<template>
    <div v-if="!!Object.keys(employee).length" class="p-employee-modal" :class="{ show: isShow }">
        <div class="p-employee-modal--inner" ref="$inner">
            <div class="--header">
                <button class="btn-close" @click="closeModal" ref="$btnClose">
                    <span class="text">{{ $t("content.close") }}</span>
                    <span class="icon">
                        <IconsClose
                            :stroke="'var(--p-dark)'"
                            :fill="'var(--p-white)'"
                            :fill-cirlce="'var(--p-white)'"
                        />
                    </span>
                </button>
            </div>

            <div class="--content">
                <div class="employee-info">
                    <div v-if="!!employee.employeePhoto.length" class="employee-photo image-wrp">
                        <UiImage
                            :src="employee.employeePhoto[0].webp"
                            :alt="employee.employeePhoto[0].title"
                            :classes="'responsive f-height'"
                        />
                    </div>
                    <div class="employee-bio">
                        <div class="name h4">{{ employee.title }}</div>
                        <div class="position">{{ employee.employeePosition }}</div>
                        <div class="links">
                            <a
                                class="link"
                                title="LinkedIn"
                                target="_blank"
                                rel="nofollow noreferrer"
                                :href="employee.employeeLinkedin"
                            >
                                <IconsLinkedin :fill="'var(--p-dark)'" />
                            </a>
                            <a class="link" :title="employee.employeeEmail" :href="`mailto:${employee.employeeEmail}`">
                                <IconsEnvelope :stroke="'var(--p-dark)'" />
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="employee.employeeDescription" class="employee-about">
                    <p>{{ employee.employeeDescription }}</p>
                </div>

                <ElementsEmployeeInsigths
                    v-if="!!Object.keys(employee).length"
                    :employee-id="parseInt(employee.id)"
                    @loaded="onLoadArticles"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onClickOutside, useMagicKeys } from "@vueuse/core";
    const { escape } = useMagicKeys();
    const store = useGlobalStore();
    const { employee } = storeToRefs(store);

    const isShow = ref(false);
    const $inner = ref(null);
    const $btnClose = ref(null);
    const isArticleLoaded = ref(false);

    watchImmediate(
        () => [employee.value, isArticleLoaded.value],
        (state) => {
            if (!!Object.keys(state[0]).length && state[1]) {
                setTimeout(() => (isShow.value = true), 450);
            }
        }
    );

    const closeModal = () => {
        isShow.value = false;
        setTimeout(() => store.setEmployee({}), 200);
    };

    const onLoadArticles = (status) => (isArticleLoaded.value = status);

    watch(escape, (e) => {
        if (e) closeModal();
    });

    onClickOutside($inner, (e) => closeModal(), {
        ignore: [$btnClose.value]
    });
</script>
