<template>
    <div class="p-mobile-navigation consultancy">
        <div class="menu">
            <nav class="c-navigation" ref="$nav">
                <ul class="parents">
                    <li
                        v-for="item in menus__.menu_general"
                        class="item"
                        :key="item.id"
                        :class="[String(item.typeLabel).toLocaleLowerCase()]"
                    >
                        <div v-if="item.typeLabel == 'Passive'" class="passive-text">{{ item.title }}</div>
                        <NuxtLink
                            v-else-if="item.typeLabel == 'Entry'"
                            :to="useConvertedLink(item.element, item)"
                            class="item-link"
                        >
                            <span class="text">{{ item.title }}</span>
                        </NuxtLink>

                        <button
                            v-if="!!item.children.length"
                            class="show-menu"
                            type="button"
                            @click="setChildrens(item)"
                        >
                            <IconsChevronr />
                        </button>
                    </li>
                    <li>
                        <UiLetsTalk />
                    </li>
                </ul>
            </nav>
        </div>
        <div class="contact">
            <div class="links">
                <NuxtLink :to="settings__.linkedin" target="_blank" rel="noopener" class="linked-in-link">
                    <span class="icon"><IconsLinkedin :fill="'var(--p-dark)'" /></span>
                    <span class="text">{{ "LinkedIn" }}</span>
                </NuxtLink>
                <NuxtLink :to="`tel:` + settings__.phone[0]?.linkUrl" :title="settings__.phone[0]?.linkText">{{
                    settings__.phone[0]?.linkText
                }}</NuxtLink>
                <NuxtLink :to="`mailto:` + settings__.emailConsultancy" :title="settings__.emailConsultancy">{{
                    settings__.emailConsultancy
                }}</NuxtLink>
            </div>
        </div>
        <div class="controls">
            <ElementsLanguageSwitcher />
        </div>

        <div class="submenu-panel" :class="{ active: !!Object.keys(childMenuItems).length }">
            <div class="submenu-panel-header">
                <button type="button" class="back-to-parent" @click="baackToParents">
                    <span class="icon"><IconsChevronl /></span>
                    <span class="text">{{ "Back" }}</span>
                </button>
            </div>
            <div class="submenu-panel-menu" v-if="!!Object.keys(childMenuItems).length">
                <ul class="childs">
                    <li class="item child-title">{{ childMenuItems.title }}</li>
                    <li class="item" v-for="child in childMenuItems.children">
                        <NuxtLink
                            v-if="child.typeLabel == 'Entry'"
                            class="item-link"
                            :to="useConvertedLink(child.element, child)"
                        >
                            <span class="text">{{ child.title }}</span>
                        </NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
    const store = useGlobalStore();
    const { menus, settings, mobileMenuToggle, languageCode } = storeToRefs(store);

    const settings__ = computed(() => settings.value[languageCode.value][0]);
    const menus__ = computed(() => menus.value[languageCode.value]);

    const childrens = ref({});

    const setChildrens = (item) => {
        childrens.value = item;
        document.body.dataset.childrenShowed = true;
    };

    const baackToParents = () => {
        childrens.value = {};
        setTimeout(() => {
            delete document.body.dataset.childrenShowed;
        }, 250);
    };

    const childMenuItems = computed(() => childrens.value);

    watch(
        () => mobileMenuToggle.value,
        (state) => {
            if (!state) {
                setTimeout(() => {
                    childrens.value = {};
                    delete document.body.dataset.childrenShowed;
                }, 250);
            }
        }
    );
</script>
