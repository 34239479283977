<template>
    <button class="btn-lets-talk" :title="$t('content.lets_talk')" @click="openFrm">
        {{ $t("content.lets_talk") }}
    </button>
</template>

<script setup>
    const store = useGlobalStore();
    const { t } = useI18n();
    const openFrm = () => store.setToggleSideForm(true);
</script>
