<template>
    <Form
        id="lest-talk-frm"
        class="lest-talk-frm"
        :class="{ loading: isLoading }"
        :validation-schema="schema"
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="onSubmitFrm"
        @invalid-submit="onInvalidSubmitFrm"
    >
        <div class="--heading">
            <div class="headline h4">{{ $t("forms.talk.title") }}</div>
            <div class="p-content left">
                <p>{{ $t("forms.talk.description") }}</p>
            </div>
        </div>
        <div class="--fieldset">
            <fieldset>
                <div class="f-line">
                    <div class="f-row">
                        <label class="f-label" for="l-name">{{ $t("forms.talk.label_name") }}</label>
                        <Field
                            type="text"
                            class="f-obj"
                            name="l-name"
                            id="l-name"
                            autocomplete="off"
                            @change="(e) => formObjectOnChange(e)"
                        />
                    </div>
                    <ErrorMessage name="l-name" />
                </div>
                <div class="f-line">
                    <div class="f-row">
                        <label class="f-label" for="l-email">{{ $t("forms.talk.label_email") }}</label>
                        <Field
                            type="email"
                            class="f-obj"
                            name="l-email"
                            id="l-email"
                            autocomplete="off"
                            @change="(e) => formObjectOnChange(e)"
                        />
                    </div>
                    <ErrorMessage name="l-email" />
                </div>
                <div class="f-line">
                    <div class="f-row">
                        <label class="f-label" for="l-company">{{ $t("forms.talk.label_company") }}</label>
                        <Field
                            type="text"
                            class="f-obj"
                            name="l-company"
                            id="l-company"
                            autocomplete="off"
                            @change="(e) => formObjectOnChange(e)"
                        />
                    </div>
                </div>
                <div class="f-line">
                    <div class="f-row">
                        <label class="f-label" for="l-message">{{ $t("forms.talk.label_message") }}</label>
                        <Field
                            as="textarea"
                            class="f-obj"
                            name="l-message"
                            id="l-message"
                            autocomplete="off"
                            cols="30"
                            rows="10"
                            @change="(e) => formObjectOnChange(e)"
                        />
                    </div>
                    <ErrorMessage name="l-message" />
                </div>
            </fieldset>
        </div>
        <div class="--footer">
            <UiPButtonSubmit
                :btn-title="submitBtnTitle"
                :btn-type="'submit'"
                :loading="isLoading"
                :btn-style="'light'"
            />
            <div class="privacy">
                <p>
                    {{ $t("forms.talk.privacy_text") }}
                    <NuxtLink
                        title="Prviacy Policy"
                        :to="
                            localePath({
                                name: 'slug',
                                params: {
                                    slug: 'privacy-policy'
                                }
                            })
                        "
                        >Prviacy Policy</NuxtLink
                    >
                </p>
            </div>
        </div>
    </Form>
</template>

<script setup>
    import { Form, Field, ErrorMessage } from "vee-validate";
    import * as yup from "yup";

    import Botpoison from "@botpoison/browser";
    const config = useRuntimeConfig();

    const { t } = useI18n();
    const isLoading = ref(false);
    const startLoading = ref(0);

    const schema = yup.object({
        "l-name": yup.string().required(t("forms.talk.errors.name_required")),
        "l-email": yup
            .string()
            .required(t("forms.talk.errors.email_required"))
            .email(t("forms.talk.errors.email_validate")),
        "l-message": yup
            .string()
            .required(t("forms.talk.errors.message_required"))
            .max(255, t("forms.talk.errors.message_validate"))
    });

    const onSubmitFrm = async (values, actions) => {
        isLoading.value = true;
        startLoading.value = 1;

        try {
            const botpoison = new Botpoison({
                publicKey: config.public.botpoisonPk
            });
            const { solution } = await botpoison.challenge();
            const ok = await $fetch("/api/formspark", {
                method: "POST",
                body: { ...values, ...{ __botpoison: solution } }
            });

            if (ok) startLoading.value = 2;
        } catch (error) {
            console.error(error);
        } finally {
            isLoading.value = false;

            setTimeout(() => {
                startLoading.value = 0;
                actions.resetForm();
            }, 1000);
        }
    };

    const onInvalidSubmitFrm = async ({ values, errors, results }) => {
        const errorsKeys = Object.keys(errors).reverse();

        if (errorsKeys.length) {
            document.getElementById(errorsKeys[0]).focus();
        }
    };

    const formObjectOnChange = (e) =>
        e.target.value != "" ? e.target.classList.add("hasValue") : e.target.classList.remove("hasValue");

    const submitBtnTitle = computed(() => {
        if (startLoading.value == 1) {
            return t("forms.talk.submit_button_sending");
        } else if (startLoading.value == 2) {
            return t("forms.talk.submit_button_done");
        }

        return t("forms.talk.submit_button_default");
    });
</script>
