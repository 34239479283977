<template>
    <div class="c-language-switcher" :class="{ show: showList }" ref="$switcher">
        <button type="button" :title="currentLocale[0].name" @click="toggleList">
            <span class="icon">
                <IconsGlobe :stroke="'var(--header-text-color)'" />
            </span>
            <span class="language">{{ currentLocale[0].name }}</span>
            <span class="arrow"><IconsChevrond :stroke="'var(--header-text-color)'" /></span>
        </button>
        <ul v-if="!!availableLocales.length" class="languages">
            <li v-for="(item, index) in availableLocales" :key="index">
                <NuxtLink :to="switchLocalePath(item.code)" @click="toggleList" class="language-link">
                    <span class="icon">
                        <IconsGlobe :stroke="'var(--header-text-color)'" />
                    </span>
                    <span class="language">{{ item.name }}</span>
                </NuxtLink>
            </li>
        </ul>
    </div>
</template>

<script setup>
    import { onClickOutside, useMagicKeys } from "@vueuse/core";
    const { escape } = useMagicKeys();
    const { locale, locales } = useI18n();
    
    const switchLocalePath = useSwitchLocalePath()

    const currentLocale = computed(() => locales.value.filter((i) => i.code == locale.value));
    const availableLocales = computed(() => locales.value.filter((i) => i.code !== locale.value));

    const showList = ref(false);
    const $switcher = ref(null);

    const toggleList = () => {
        showList.value = !showList.value;
    };

    const closeList = () => (showList.value = false);

    watch(escape, (e) => {
        if (e) closeList();
    });

    onClickOutside($switcher, (e) => closeList());
</script>
