<template>
    <header id="p-header" class="p-header consultancy">
        <div class="c-container">
            <div class="p-header--grid">
                <div class="c-logo">
                    <NuxtLink
                        :to="
                            localePath({
                                name: 'index'
                            })
                        "
                        class="link-home"
                    >
                        <IconsClogo />
                    </NuxtLink>
                </div>
                <nav class="c-navigation" aria-label="desktop-navigation">
                    <MenuConsultancy />
                </nav>
                <div class="c-custom">
                    <ElementsLanguageSwitcher v-show="isDesktop" />
                    <UiLinkedIn v-show="isDesktop" />
                    <UiLetsTalk v-show="isDesktop" />
                    <UiHamburger v-show="!isDesktop" />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
    const localePath = useLocalePath();
    const isDesktop = useMediaQuery("(min-width: 1181px)");
</script>
