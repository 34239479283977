<template>
    <div v-if="status == 'success' && !!items.length" class="employee-articles">
        <div class="articles-title h6">{{ $t("content.title_articles") }}</div>
        <article class="article" v-for="insight in items" :key="insight.id">
            <div class="article-media">
                <NuxtLink
                    :title="insight.title"
                    :to="
                        localePath({
                            name: 'news-slug',
                            params: {
                                slug: insight.slug
                            }
                        })
                    "
                >
                    <UiImage :src="insight.previewImage[0].webp" :alt="insight.previewImage[0].title" />
                </NuxtLink>
            </div>
            <div class="article-title">
                <NuxtLink
                    :title="insight.title"
                    :to="
                        localePath({
                            name: 'news-slug',
                            params: {
                                slug: insight.slug
                            }
                        })
                    "
                    >{{ insight.title }}</NuxtLink
                >
            </div>
        </article>

        <div class="employee-more-links">
            <NuxtLink
                :to="
                    localePath({
                        name: 'slug',
                        params: {
                            slug: t('content.view_all_insights_slug')
                        }
                    })
                "
                :title="t('content.more_articles_button')"
                class="simple-link"
            >
                <span class="text">{{ $t("content.more_articles_button") }}</span>
                <span class="icon">
                    <IconsChevronr />
                </span>
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        employeeId: {
            type: Number,
            default: 0
        }
    });

    const emit = defineEmits(["loaded"]);
    const { t } = useI18n();
    const localePath = useLocalePath();

    const { data: insights, status } = await useAsyncGql({
        operation: "employeeinsights",
        variables: {
            siteId: currentLang(),
            employeeId: props.employeeId
        }
    });

    const items = computed(() => insights.value?.insights);

    onMounted(() => {
        emit("loaded", true);
    });
</script>
