<template>
    <ul class="parents">
        <li
            v-for="item in menus__.menu_general"
            :key="item.id"
            class="item"
            :class="[String(item.typeLabel).toLocaleLowerCase(), { fullWidth: item.fullWidth }]"
        >
            <button v-if="item.typeLabel == 'Passive'" type="button" class="item-link">
                <span class="text">{{ item.title }}</span>
                <span v-if="!!item.children.length" class="icon">
                    <IconsChevrond :stroke="'var(--header-text-color)'" />
                </span>
            </button>

            <NuxtLink
                v-else-if="item.typeLabel == 'Entry'"
                :to="useConvertedLink(item.element, item)"
                class="item-link"
            >
                <span class="text">{{ item.title }}</span>
                <span v-if="!!item.children.length" class="icon">
                    <IconsChevrond />
                </span>
            </NuxtLink>

            <ul class="childs" v-if="!!item.children.length && item.fullWidth">
                <li v-if="item.fullWidth" class="item child-title h5">{{ item.title }}</li>
                <li class="item" v-for="child in item.children">
                    <NuxtLink
                        v-if="child.typeLabel == 'Entry'"
                        class="item-link"
                        :to="useConvertedLink(child.element, child)"
                    >
                        <span class="media">
                            <div v-if="!!child.previewImage.length" class="image-wrp">
                                <UiImage
                                    :src="child.previewImage[0].webp"
                                    :alt="child.previewImage[0].title"
                                    :classes="'responsive f-height'"
                                />
                            </div>
                        </span>
                        <span class="text">{{ child.title }}</span>
                    </NuxtLink>
                </li>
            </ul>
            <ul class="childs" v-else-if="!!item.children.length">
                <li class="item" v-for="child in item.children">
                    <NuxtLink
                        v-if="child.typeLabel == 'Entry'"
                        class="item-link"
                        :to="useConvertedLink(child.element, child)"
                    >
                        <span class="text">{{ child.title }}</span>
                        <span class="icon">
                            <IconsChevronr />
                        </span>
                    </NuxtLink>
                </li>
            </ul>
        </li>
        <!-- <li>
            <button class="btn-search" type="button" @click="onToggleSearch">
                <IconsSearch :stroke="'var(--header-text-color)'"/>
            </button>
        </li> -->
    </ul>
</template>

<script setup>
    const store = useGlobalStore();
    const { menus, languageCode } = storeToRefs(store);

    const menus__ = computed(() => menus.value[languageCode.value]);

    const onToggleSearch = () => store.setToggleSearchForm(true);
</script>
