<template>
    <div class="p-lets-talk" :class="{ show: toggleSideForm }">
        <div class="p-lets-talk--inner" ref="$inner">
            <div class="--header">
                <button class="btn-close" @click="closeModal" ref="$btnClose">
                    <span class="text">{{ $t("content.close") }}</span>
                    <span class="icon">
                        <IconsClose />
                    </span>
                </button>
            </div>
            <div class="--content">
                <FormsLetsTalk />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onClickOutside, useMagicKeys } from "@vueuse/core";
    const { escape } = useMagicKeys();
    const $inner = ref(null);
    const $btnClose = ref(null);
    const store = useGlobalStore();
    const { toggleSideForm } = storeToRefs(store);

    const closeModal = () => store.setToggleSideForm(false);

    watch(escape, (e) => {
        if (e) closeModal();
    });

    onClickOutside($inner, (e) => closeModal(), {
        ignore: [$btnClose.value]
    });
</script>
