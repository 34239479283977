<template>
    <NuxtLink :to="settings__.linkedin" target="_blank" title="LinkedIn" rel="noopener" class="linked-in-link">
        <span class="icon"><IconsLinkedin /></span>
    </NuxtLink>
</template>

<script setup>
    const store = useGlobalStore();
    const { settings, languageCode } = storeToRefs(store);

    const settings__ = computed(() => settings.value[languageCode.value][0]);
</script>
